<template>
  <div class="index">
    <nav-bar />
    <app-main ref="main">
      <!-- 顶部广告 -->
      <template v-slot:main-top>
        <div @click="adClick" class="ad" ref="ad">
          <img src="../../assets/images/mall/adddd.png" />
        </div>
      </template>
      <!-- 主要内容 -->
      <template v-slot:main-content>
        <!-- 录入收件人信息 -->
        <!-- 店铺导入 -->
        <!-- Step3 -->
        <div class="step three" ref="stepThree">
          <!-- 仓库 -->
          <div class="screening">
            <div class="title">仓库:</div>
            <ul class="list">
              <li @click="storeItemClick(index)" :class="{ 'screening-active': storeActive == index }" class="item" v-for="(item, index) in storeList" :key="index">
                {{ item.name }}
              </li>
            </ul>
          </div>
          <!-- 排序 -->
          <div class="screening">
            <div class="title">排序:</div>
            <ul class="list">
              <li @click="sortItemClick(index)" :class="{ 'screening-active': sortActive == index }" class="item" v-for="(item, index) in sortList" :key="index">
                {{ item }}
              </li>
            </ul>
          </div>
          <!-- <p class="stepTitle">Step3：选择礼品</p> -->

          <div class="step-body step-three">
            <!-- 左边 -->
            <div class="step-body-left">
              <!-- 礼品列表 -->
              <div class="goods">
                <div class="goods-item" v-for="(item, index) in goodsList.slice((goodsCurrentPage - 1) * goodsPageSize, goodsCurrentPage * goodsPageSize)" :key="index">
                  <img :src="item.goods_images[0]" @click="toMall" />

                  <!-- <div class="btn" @click="addGoods(item)">确认礼品</div> -->
                  <div class="price">￥{{ item.goods_price }}</div>
                  <div class="message">
                    <span>{{ item.goods_name }}</span>
                    <span>{{ item.goods_weight }}g</span>
                  </div>
                </div>
              </div><br>
              <!-- 分页 -->
                 <el-pagination background @current-change="goodsHandleCurrentChange" :current-page="goodsCurrentPage"
              :page-size="goodsPageSize" layout="total, prev, pager, next, jumper" :total="goodsList.length"> </el-pagination>

            </div>
          </div>
        </div>
      </template>
    </app-main>
    <!-- <bottom-footer /> -->
    <back-top />
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
import NavBar from 'components/navbar/NavBar'

// import BottomFooter from 'components/bottomfooter/BottomFooter'
import BackTop from 'components/backtop/BackTop'

import { getAllStorage, getAllGoods, goodsSearchGs } from 'network/api'
// import { mustTwoDecimal } from 'utils/utils'
// import commonstate from '../../../../public/config.js'

export default {
  components: {
    AppMain,
    NavBar,
    // BottomFooter,
    BackTop
  },
  data () {
    return {
      /* 以下为地址数据 */
      currentPage: 1, // 当前页
      pageSize: 10, // 每页多少条
      // 编辑地址对象
      storeList: [], // 仓库列表
      storeListItemId: null, // 仓库id
      sortList: ['默认', '价格从低到高', '', '重量从轻到重'],
      storeActive: 0,
      sortActive: 1,
      goodsPageSize: 20,
      goodsCurrentPage: 1,
      goodsList: [], // 礼品列表
      goodsItem: {}, // 礼品详情
      /* 以上为礼品数据 */
      areaListValue: '',
      goodsType: 'goods_price', // 商品排序标识
      url: ''
    }
  },
  mounted () {
    window._agl && window._agl.push(['track', ['success', { t: 18 }]])
  },
  created () {
    // 获取所有仓库信息key: commonstate.key
    getAllStorage({}).then(res => {
      // console.log(res)
      this.storeList = res.data
      // 向数组中添加一个'全部'
      const i = { name: '全部' }
      if (this.storeList) {
        this.storeList.unshift(i)
      }
    })
    // 获取所有商品
    this.allGoods()
    this.getGoodsSearchGs()
    this.url = this.$url
  },
  methods: {
    // 广告点击
    adClick () {
      // window.open(this.$store.getters.getSiteInfo.online_service, '_blank')
    },
    // 点击选购
    toMall () {
      this.$router.push('/orderAdmin/purchase/')
    },

    // 获取所有商品请求
    allGoods () {
      // getAllGoods().then(res => {
      //   this.goodsList = res.data key: commonstate.key
      // })
      getAllGoods({}).then(res => {
        this.goodsList = res.data
      })
    },
    // 商品筛选请求
    getGoodsSearchGs () {
      goodsSearchGs({
        storage_id: this.storeListItemId,
        goods_type: this.goodsType
        // key: commonstate.key
      }).then(res => {
        // 筛选倒序数组
        switch (this.goodsType) {
          // 价格从低到高
          case 'goods_price':
            this.goodsList = res.data.reverse()
            break
          // 销量从低到高
          case 'goods_num':
            this.goodsList = res.data.reverse()
            break
          // 重量从轻到重
          case 'goods_weight':
            this.goodsList = res.data.reverse()
            break
          // 默认
          default:
            this.goodsList = res.data
            break
        }
      })
    },
    // 添加礼品
    addGoods (item) {
      // 首先需要判断是否选择了平台
      if (this.stepOneActive != null) {
        // 添加前清空
        this.storageOptions = []
        // 选中的礼品详情
        this.lipinList = []
        this.lipinList.push(item)
        this.dataFlag = true
        this.goodsItem = item
        this.fprice = this.storeListItem.deliver_price
        // this.fprice = this.storeListItem
        // // 选择仓库<el-option>使用
        const v = {}
        v.value = this.storeListItem.id
        v.label = this.storeListItem.deliver_name + ' / ' + this.storeListItem.deliver_price + '元'
        v.freight_price = this.storeListItem.deliver_price
        this.storageOptions.push(v)
        this.storageValue = this.storageOptions[0].value // 初始化<el-select>选中
        this.fPrice = this.storageOptions[0].freight_price // 初始化运费
        // this.dataFlag = true // 礼品列表显示标识
        // /* 以下为创建一个行添加到表格 */
        // // 获取tbody
        // var tb = document.getElementsByClassName('table-tbody')[0]
        // // 添加前清空子元素
        // tb.innerHTML = ''
        // // 创建行
        // var tr = document.createElement('tr')
        // // 第一列
        // var imgTd = document.createElement('td')
        // var img = document.createElement('img')
        // img.src = item.goods_images[0]
        // imgTd.appendChild(img)
        // tr.appendChild(imgTd)
        // // 第二列
        // var nameTd = document.createElement('td')
        // nameTd.innerHTML = item.goods_name
        // tr.appendChild(nameTd)
        // // 第三列
        // var priceTd = document.createElement('td')
        // priceTd.innerHTML = item.goods_price
        // tr.appendChild(priceTd)
        // // 第四列
        // var numberTd = document.createElement('td')
        // numberTd.innerHTML = 1
        // tr.appendChild(numberTd)
        // // 第五列
        // var weightTd = document.createElement('td')
        // weightTd.innerHTML = item.goods_weight
        // tr.appendChild(weightTd)
        // // 第六列
        // var storeTd = document.createElement('td')
        // storeTd.innerHTML = this.storeListItemName
        // tr.appendChild(storeTd)
        // // 第七列
        // var subtotalTd = document.createElement('td')
        // subtotalTd.innerHTML = item.goods_price
        // tr.appendChild(subtotalTd)
        // // 添加样式
        // var tds = tr.children
        // img.style.width = '48px'
        // img.style.height = '48px'
        // for (let i = 0; i < tds.length; i++) {
        //   tds[i].style.padding = '16px'
        //   tds[i].style.borderBottom = '1px solid #e8e8e8'
        // }
        // // 添加到tbody
        // tb.appendChild(tr)
        /* 以上为创建一个行添加到表格 */
        /* 获取到发件人列表 */
        // getAreaList().then(res => {
        //   this.areaList = res.data // 发件人地址列表
        //   for (let i = 0; i < this.areaList.length; i++) {
        //     if (this.areaList[i].status === '1') {
        //       this.areaListValue = this.areaList[i].name + ' ' + this.areaList[i].send_phone // 初始化默认发件人地址
        //       this.area_id = this.areaList[i].id // 初始化发件人地址id
        //       this.oid = this.areaList[i].oid //
        //       return
        //     }
        //   }
        // })
      }
    },
    // 当前页
    handleCurrentChange (val) {
      this.currentPage = val
    },
    // 仓库筛选点击
    storeItemClick (index) {
      this.storeActive = index
      this.storeListItem = this.storeList[index] // 仓库对象
      this.storeListItemName = this.storeList[index].name // 获取到仓库名
      this.storeListItemId = this.storeList[index].id // 获取到仓库ID
      // 根据仓库获取礼品列表
      this.getGoodsSearchGs()
    },
    // 排序筛选点击
    sortItemClick (index) {
      this.sortActive = index
      switch (index) {
        case 0:
          this.goodsType = ''
          if (!this.storeListItemId) {
            this.allGoods()
          } else {
            this.getGoodsSearchGs()
          }
          break
        case 1:
          this.goodsType = 'goods_price'
          this.getGoodsSearchGs()
          break
        case 2:
          this.goodsType = 'goods_num'
          this.getGoodsSearchGs()
          break
        case 3:
          this.goodsType = 'goods_weight'
          this.getGoodsSearchGs()
          break
      }
    },
    // 分页 当前页
    goodsHandleCurrentChange (val) {
      this.goodsCurrentPage = val
    },
    // 仓库选择框
    storageSelectChange (e) {
      this.fprice = e.freight_price
    }
  }
}
</script>
<style scoped src="styles/views/index/hotshop.css">

</style>
